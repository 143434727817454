import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  closeModal,
  saveModal,
  handleChange,
  revertRedirect,
} from "../state/formSlice";
import { useNavigate } from "react-router-dom";
function FormTemplate() {
  const serviceFor = useSelector((state) => state.form.serviceFor);
  const dispatch = useDispatch();
  return (
    <Form>
      <Form.Group as={Row} className="mb-3" controlId="requestedFor">
        <Form.Label column md="3">
          Requested for
        </Form.Label>
        <Col md="9">
          <Form.Control
            type="text"
            onChange={(e) => dispatch(handleChange(e))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="serviceFor">
        <Form.Label column md="3">
          Service for
        </Form.Label>
        <Col md="9">
          <Form.Control plaintext readOnly defaultValue={serviceFor} />
        </Col>
      </Form.Group>

      <Form.Group as={Row} className="mb-3" controlId="mobileNo">
        <Form.Label column sm="3">
          Mobile no.
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="tel"
            onChange={(e) => dispatch(handleChange(e))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="preferedTime">
        <Form.Label column sm="3">
          Prefered time
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="time"
            onChange={(e) => dispatch(handleChange(e))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="address">
        <Form.Label column md="3">
          Address
        </Form.Label>
        <Col md="9">
          <Form.Control
            as={"textarea"}
            onChange={(e) => dispatch(handleChange(e))}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row} className="mb-3" controlId="issueDescription">
        <Form.Label column md="3">
          Issue Description
        </Form.Label>
        <Col md="9">
          <Form.Control
            as={"textarea"}
            onChange={(e) => dispatch(handleChange(e))}
          />
        </Col>
      </Form.Group>
    </Form>
  );
}

function BookingForm(props) {
  // const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const show = useSelector((state) => state.form.modalState);
  const formData = useSelector((state) => state.form);
  const isRedirect = useSelector((state) => state.form.redirect);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("executing use effect");
    if (isRedirect) {
      navigate("/bookings");
      dispatch(revertRedirect());
      console.log("navigating");
    }
  }, [isRedirect, navigate, dispatch]);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

      <Modal show={show} onHide={() => dispatch(closeModal())} centered>
        <Modal.Header closeButton>
          <Modal.Title>Book a call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormTemplate />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => dispatch(closeModal())}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() =>
              dispatch(saveModal({ ...formData, name: props.user }))
            }
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BookingForm;
