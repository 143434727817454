import { ListGroup } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import React from "react";
import { openModal } from "../state/formSlice";
import { useDispatch } from "react-redux";
import Card from "react-bootstrap/Card";

function ImageTitleCard({ imageUrl, title }) {
  return (
    <Card>
      <div className="d-flex justify-content-center">
        <Card.Img
          variant="top"
          src={imageUrl}
          alt={title}
          style={{ width: "100px", height: "100px" }}
        />
      </div>

      <Card.Body>
        <Card.Title>{title}</Card.Title>
      </Card.Body>
    </Card>
  );
}

function ServiceList() {
  const dispatch = useDispatch();
  return (
    <Container className="mt-5 ">
      <Row className="justify-content-md-center">
        <Col md={5}>
          <ListGroup>
            <ListGroup.Item
              className="text-center"
              action
              onClick={() => dispatch(openModal({ type: "Washing Machine" }))}
            >
              <ImageTitleCard
                imageUrl="washing_machine.svg"
                title="Washing Machine"
              />
            </ListGroup.Item>
            <ListGroup.Item
              className="text-center"
              action
              onClick={() => dispatch(openModal({ type: "Micro Oven" }))}
            >
              <ImageTitleCard imageUrl="microwave.svg" title="Micro Oven" />
            </ListGroup.Item>
            <ListGroup.Item
              className="text-center"
              action
              onClick={() => dispatch(openModal({ type: "Geyser" }))}
            >
              <ImageTitleCard imageUrl="water_heater.svg" title="Geyser" />
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
}

export default ServiceList;
