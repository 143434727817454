import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { NavLink, Outlet } from "react-router-dom";
import AboutUs from "./AboutUs";

function HeaderSms() {
  return (
    <>
      <Navbar bg="light" expand="lg" style={{ fontSize: "18px" }}>
        <Container>
          <Navbar.Brand>
            <NavLink className="nav-link" to="/">
              <img
                alt=""
                src="smslogo_225_275.png"
                width="40" // Adjusted width to fit the logo nicely
                height="40" // Adjusted height to fit the logo nicely
                className="d-inline-block align-top"
              />{" "}
              <span style={{ fontSize: "1.2em", fontWeight: "bold" }}>SMS</span>
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              <Nav.Item>
                <NavLink
                  className="nav-link"
                  to="/bookings"
                  style={{ fontSize: "1.1em" }}
                >
                  Bookings
                </NavLink>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      <AboutUs />
    </>
  );
}

export default HeaderSms;
