import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Telephone } from "react-bootstrap-icons"; // Importing the phone icon

const AboutUs = () => {
  return (
    <section
      className="about-us"
      style={{
        backgroundColor: "#f8f9fa",
        padding: "20px",
        marginTop: "20px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Container>
        <h2
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
        >
          About Us
        </h2>
        <hr />
        <Row>
          <Col>
            <p style={{ fontSize: "16px" }}>
              We were established on January 18, 2018, with a mission to provide
              top-notch home appliance services across Chennai. Our expertise
              lies in repairing Washing Machines, Microwaves, and Geysers, as
              well as offering secondhand sales of appliances. With over 8 years
              of industry experience, our team specializes in these repairs,
              having previously worked with IFB, HouseJoy, and Urbanclap. We
              take pride in our customer satisfaction, having served over 2500
              customers with positive feedback. Our commitment to quality and
              reliability ensures that we use genuine spare parts and offer
              cost-effective repair options, always striving to provide
              dependable services.
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="contact-info" style={{ marginTop: "20px" }}>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                Contact Information
              </h3>
              <hr />
              <table style={{ borderCollapse: "collapse" }}>
                <tbody>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <strong>Service Provider:</strong>
                    </td>
                    <td style={{ padding: "5px" }}>A. Murugapandian</td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <strong>Contact Number:</strong>
                    </td>
                    <td style={{ padding: "5px" }}>
                      <a
                        href="tel:7448750731"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Telephone size={20} />
                        &nbsp; 7448750731
                      </a>
                      ,{" "}
                      <a
                        href="tel:9551947653"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <Telephone size={20} />
                        &nbsp; 9551947653
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ padding: "5px" }}>
                      <strong>Address:</strong>
                    </td>
                    <td style={{ padding: "5px" }}>
                      34A, Big Street, Ambal Nagar Main Road, Keelkattalai,
                      Chennai- 600117 (Near Perumal Temple)
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutUs;
