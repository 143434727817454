import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { createOrder } from "../graphql/mutations";

const initialState = {
  modalState: false,
  requestedFor: "",
  serviceFor: "",
  mobileNo: "",
  preferedTime: "",
  address: "",
  issueDescription: "",
  status: "inprogress",
  redirect: false,
};

export const saveModal = createAsyncThunk(
  "form/saveModal",
  async (formData, thunkAPI) => {
    try {
      const d = new Date();
      const time = d.getTime();
      const data = {
        id: formData.name + formData.serviceFor + time,
        name: formData.name,
        requestedFor: formData.requestedFor,
        description: formData.issueDescription,
        phoneNo: formData.mobileNo,
        issueType: formData.serviceFor,
        address: formData.address,
        preferedTime: formData.preferedTime,
        status: formData.status,
      };
      console.log(data);
      await API.graphql({
        query: createOrder,
        variables: { input: data },
      });

      return formData;
    } catch (error) {
      // Handle error appropriately
      console.error("Error saving modal:", error);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    openModal(state, action) {
      state.modalState = true;
      state.redirect = false;
      state.serviceFor = action.payload.type;
    },
    closeModal(state) {
      //   state.modalState = false;
      //   state = initialState;
      return initialState;
    },
    revertRedirect(state) {
      state.redirect = false;
    },
    handleChange(state, action) {
      const { id, value } = action.payload.target;
      state[id] = value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveModal.fulfilled, (state, action) => {
      // Handle fulfilled state if needed
      return { ...initialState, redirect: true };
    });
  },
});

export const { openModal, closeModal, handleChange, revertRedirect } =
  formSlice.actions;

export default formSlice.reducer;
