import { BrowserRouter, Route, Routes } from "react-router-dom";
import Bookings from "./components/Bookings";
import HeaderSms from "./components/HeaderSms";
import ServiceList from "./components/ServiceList";
import BookingForm from "./components/BookingForm";
import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify, Auth } from "aws-amplify";
import aws_exports from "./aws-exports";
import { useDispatch } from "react-redux";
import { fetchOrders } from "./state/userSlice";
import { useEffect } from "react";
Amplify.configure(aws_exports);
function App({ signOut, user }) {
  // const dispatch = useDispatch()
  // useEffect(()=>{dispatch(fetchOrders())},[])

  // console.log(user.storage)
  console.log(user);
  // console.log(user.userDataKey)
  // console.log(JSON.parse(user.storage["CognitoIdentityServiceProvider.3e8fsdrudqt010jgucgf6s2jkh.google_116918306672450350698.userData"]))

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeaderSms />}>
          <Route index element={<ServiceList />} />
          <Route
            path="bookings"
            element={
              <Bookings
                user={
                  user.username === "google_113810540158458964651"
                    ? "admin"
                    : user.username
                }
              />
            }
          />
        </Route>
      </Routes>
      {/* <BookingForm user={user.username}/> */}
      <BookingForm
        user={
          user.username === "google_113810540158458964651"
            ? "admin"
            : user.username
        }
      />
      {/* <h1>Hello {user.username}</h1>
      <button onClick={signOut}>Sign out</button> */}
    </BrowserRouter>
  );
}

export default withAuthenticator(App);
// export default App;
