import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "aws-amplify";
import { listOrders } from "../graphql/queries";
import { updateOrder, deleteOrder } from "../graphql/mutations";
const initialState = {
  username: "",
  orderDetailsLoading: false,
  orders: [],
};
export const fetchOrders = createAsyncThunk(
  "user/fetchOrders",
  async (userId) => {
    if (userId === "admin") {
      var result = await API.graphql({ query: listOrders });
    } else {
      var result = await API.graphql({
        query: listOrders,
        variables: { filter: { name: { eq: userId } } },
      });
    }
    return result.data.listOrders.items;
  }
);

export const updateStatus = createAsyncThunk(
  "user/updateStatus",
  async ({ id, status }) => {
    if (status === "delete") {
      const variables = {
        input: { id },
      };

      API.graphql({
        query: deleteOrder,
        variables: variables,
      })
        .then((result) => {
          console.log("Order deleted successfully:", result.data.updateOrder);
        })
        .catch((error) => {
          console.error("Error updating order:", error);
        });
    } else {
      const variables = {
        input: { id, status },
      };

      API.graphql({
        query: updateOrder,
        variables: variables,
      })
        .then((result) => {
          console.log("Order updated successfully:", result.data.updateOrder);
        })
        .catch((error) => {
          console.error("Error updating order:", error);
        });
    }
    return { id, status };
  }
);
const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      // Create a new array by sorting the payload
      const sortedOrders = [...action.payload].sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );

      // Return a new state object with the updated orders array
      return {
        ...state,
        orders: sortedOrders,
      };
    });
    builder.addCase(updateStatus.fulfilled, (state, action) => {
      console.log(action);
      const { id, status } = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.id === id);

      // If order with given ID exists
      if (orderIndex !== -1) {
        // Create a new array of orders with the updated status
        const updatedOrders = [...state.orders];
        if (status === "delete") {
          updatedOrders.splice(orderIndex, 1);
        } else {
          updatedOrders[orderIndex] = {
            ...updatedOrders[orderIndex],
            status: status,
          };
        }
        console.log(`Status updated for order ID ${id}`);

        // Return a new state object with updated orders array
        return {
          ...state,
          orders: updatedOrders,
        };
      } else {
        console.log(`Order with ID ${id} not found`);
        return state; // Return the current state if the order is not found
      }
    });
  },
});
export const { updateUser } = userSlice.actions;
export default userSlice.reducer;
