import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { fetchOrders, updateStatus } from "../state/userSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

const formatDate = (dateString, onlyDate = false) => {
  const date = new Date(dateString);

  // Extracting date components
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because month is zero-based
  const day = date.getDate().toString().padStart(2, "0");
  const hour = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Creating the formatted date string
  let formattedDate;
  if (onlyDate) {
    formattedDate = `${year}-${month}-${day}`;
  } else {
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = (hour % 12 || 12).toString().padStart(2, "0"); // Convert to 12-hour format
    formattedDate = `${year}-${month}-${day} at ${formattedHour}:${minutes} ${ampm}`;
  }

  return formattedDate;
};

const getStatusColor = (status) => {
  switch (status) {
    case "inprogress":
      return "warning"; // Yellow color for inprogress
    case "scheduled":
      return "info"; // Blue color for scheduled
    case "completed":
      return "success"; // Green color for completed
    default:
      return "dark"; // Default color
  }
};

function Order(props) {
  // console.log(props);
  const dispatch = useDispatch();
  const rowStyle = {
    marginTop: "10px",
    display: props.hideOption ? "none" : undefined, // Hide if condition is true, otherwise show
  };
  const statusColor = getStatusColor(props.details.status);
  return (
    <Card style={{ fontFamily: "Arial, sans-serif", fontSize: "16px" }}>
      <Card.Body>
        <div className="mb-3">
          {" "}
          {/* Add margin-bottom to create space */}
          <div>
            <Card.Title>
              {props.details.issueType} -{" "}
              {formatDate(props.details.createdAt, true)}
            </Card.Title>
            <Card.Subtitle className={`text-${statusColor}`}>
              {props.details.status}
            </Card.Subtitle>
          </div>
          <hr className="my-2" /> {/* Divider line */}
        </div>

        <Card.Text style={{ fontSize: "16px" }}>
          Requested for {props.details.requestedFor}
        </Card.Text>
        <Card.Text style={{ fontSize: "16px" }}>
          Booked on {formatDate(props.details.createdAt)}
        </Card.Text>
        <Card.Text style={{ fontSize: "16px" }}>
          {props.details.description}
        </Card.Text>

        <Row style={rowStyle}>
          {" "}
          {/* Apply custom style to Row */}
          <Col xs={6}>
            <Button
              variant="primary"
              href={`tel:${props.details.phoneNo}`}
              block
              style={{ fontSize: "16px" }}
            >
              call
            </Button>
          </Col>
          <Col xs={6}>
            <Dropdown>
              <Dropdown.Toggle
                variant="secondary"
                block
                style={{ fontSize: "16px" }}
              >
                Actions
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      updateStatus({
                        id: props.details.id,
                        status: "inprogress",
                      })
                    )
                  }
                >
                  In Progress
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      updateStatus({
                        id: props.details.id,
                        status: "scheduled",
                      })
                    )
                  }
                >
                  Scheduled
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      updateStatus({
                        id: props.details.id,
                        status: "completed",
                      })
                    )
                  }
                >
                  Completed
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() =>
                    dispatch(
                      updateStatus({
                        id: props.details.id,
                        status: "delete",
                      })
                    )
                  }
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function Bookings(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOrders(props.user));
  }, [props.user, dispatch]);
  const [filterStatus, changeFilterStatus] = useState("all");
  const orders = useSelector((state) => state.user.orders);
  console.log(orders);
  return (
    <Container className="mt-5">
      <Dropdown>
        <Dropdown.Toggle variant="secondary" block>
          Filter Status : {filterStatus}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => changeFilterStatus("inprogress")}>
            Inprogress
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeFilterStatus("scheduled")}>
            Scheduled
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeFilterStatus("completed")}>
            Completed
          </Dropdown.Item>
          <Dropdown.Item onClick={() => changeFilterStatus("all")}>
            all
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Row
        className="justify-content-md-center overflow-auto"
        style={{ height: "60vh" }}
      >
        {orders.map((order) => {
          if (order.status === filterStatus || filterStatus === "all") {
            return (
              <Col md={4} className="mt-2" key={order.id}>
                <Order
                  details={order}
                  key={order.id}
                  hideOption={props.user !== "admin"}
                />
              </Col>
            );
          }
          return null;
        })}
      </Row>
    </Container>
  );
}

export default Bookings;
